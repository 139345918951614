<template>
  <div id="global-box">
    <div class="button-box">
      <img src="../../assets/title.svg" alt="图标" class="img" />
      <a-button type="primary" class="rubbish-button" @click="openJDapi">垃圾智能识别</a-button>
    </div>
    <!--展示图片的盒子-->
    <div class="images-box">
      <div v-for="(item, index) in imagesList" :key="index" class="item-box" @click="showPhotoDetails(index)">
        <img :src="item.photo.photoUrl" alt="魅力东疆" ref="imgRef" />
      </div>
    </div>
  </div>
  <!--右部智能识别的盒子-->
<!--    <div class="analysis-box">-->
<!--      <input-->
<!--          type="file"-->
<!--          id="File"-->
<!--          ref="fileRef"-->
<!--          style="display: none;"-->
<!--          @change="imgToBase64"-->
<!--      />-->
<!--      <div class="button-box">-->
<!--        <a-button type="primary" @click="chooseImage" class="analysis-button">上传待识别图片</a-button>-->
<!--        <a-button-->
<!--            @click="useJDapi"-->
<!--            :loading="isLoading"-->
<!--            class="analysis-button"-->
<!--            style="background: #0E9A00; color: #FFF"-->
<!--        >-->
<!--          识别图片-->
<!--        </a-button>-->
<!--        <a-button class="back-button" @click="gotoLogin">-->
<!--          <template #icon><ArrowRightOutlined /></template>-->
<!--        </a-button>-->
<!--      </div>-->
<!--      <img src="../assets/image.png" ref="imgRef">-->
<!--      <a-table :dataSource="dataGarbage" :columns="columns" class="a-table"/>-->
<!--    </div>-->
  <!-- 京东api调用的地方 -->
  <a-modal
      v-model:visible="visibleJD"
      title="智能识别垃圾"
      @ok="closeJDapi"
      @cancel="closeJDapi"
      okText="确认"
      cancelText="取消"
      width="95%"
  >
    <a-tag color="green">点击识别按钮将识别上传图片中的垃圾</a-tag>
    <input
        type="file"
        id="File"
        ref="fileRef"
        style="display: none;"
        @change="imgToBase64"
    />
    <div style="margin-top: 20px;">
      <a-button type="primary" @click="chooseImage" style="margin-right: 20px;">上传待识别图片</a-button>
      <a-button @click="useJDapi" :disabled="base64Url.length === 0" :loading="isLoading">识别图片</a-button>
    </div>
    <img src="" ref="imgRef" style="width: 200px; margin-top: 20px; margin-bottom: 20px;">
    <a-table :dataSource="dataGarbage" :columns="columns" v-show="dataGarbage.length !== 0"/>
  </a-modal>
  <!--查看图片的模态框-->
  <a-modal v-model:visible="visible" title="图片详情" :footer="null" width="95%">
    <a-image :src="photoDetails.url"></a-image>
    <a-descriptions style="width: 100%; margin-top: 10px;">
      <a-descriptions-item label="拍摄时间" style="width: 100%; display: block">
        {{photoDetails.time}}
      </a-descriptions-item>
      <a-descriptions-item label="图片描述" style="width: 100%; display: block">
        {{photoDetails.message}}
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script>
import { defineComponent } from "vue";
import { getScrollHeight, getScrollTop, getWindowHeight } from "@/tools/screen";
// import { ArrowRightOutlined } from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import OSS from "ali-oss";
export default defineComponent({
  // components: {
  //   ArrowRightOutlined
  // },
  data() {
    return {
      //图片列表
      imagesList: [],
      //分页条件
      limit: 21,
      page: 1,
      totalSize: 0,
      //模态框是否展示
      visible: false,
      photoDetails: {
        url: "",
        time: "",
        message: ""
      },

      //京东API需要的数据
      //打开京东api的弹窗
      visibleJD: false,
      photoUrl: "",
      isLoading: false,
      dataGarbage: [],
      columns: [
        {
          title: '垃圾名称',
          dataIndex: 'garbage_name',
          key: 'garbage_name',
          align: "center",
          width: '15%'
        },
        {
          title: '垃圾分类',
          dataIndex: 'cate_name',
          key: 'cate_name',
          align: "center",
          width: '15%'
        },
        {
          title: '识别可信度',
          dataIndex: 'confidence',
          key: 'confidence',
          align: "center",
          width: '20%'
        },
        {
          title: '备注描述',
          dataIndex: 'ps',
          key: 'ps',
          align: "center",
          width: '50%'
        }
      ],
      base64Url: ""
    }
  },
  methods: {
    //前往登录界面
    gotoLogin() {
      this.$router.push({
        path: "/login"
      });
    },
    //获取魅力东疆的图片
    async getCharmingImages() {
      let result = await this.$axios({
        url: "http://39.100.158.75:8080/showPhoto/getAllPassedShowPhotoByPage",
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          limit: this.limit,
          page: this.page,
        }
      });
      this.page++;
      this.totalSize = Math.ceil(result.data.data.totalPageCount/this.limit);
      for (let i of result.data.data.list) {
        this.imagesList.push(i);
      }
    },
    //查看图片详情
    showPhotoDetails(index) {
      this.photoDetails.url = this.imagesList[index * 1].photo.photoUrl;
      this.photoDetails.time = this.imagesList[index * 1].time;
      this.photoDetails.message = this.imagesList[index * 1].message;
      this.visible = true;
    },
    //监听滚轴的变化
    scrollListen() {
      if (getScrollTop() + getWindowHeight() >= getScrollHeight() && this.visible === false) {
        if (this.page <= this.totalSize) {
          this.getCharmingImages();
        } else {
          message.success("图片已完全加载", 0.8);
        }
      }
    },

    //京东API部分-------------------------------------------------------------------------------------------------------
    //打开京东api对话框
    openJDapi() {
      this.visibleJD = true;
    },
    //关闭弹窗
    closeJDapi() {
      this.visibleJD = false;
      this.base64Url = '';
      this.$refs.imgRef.src = '';
      this.dataGarbage = [];
    },
    //选择需要识别的图片
    //选择图片
    chooseImage() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },
    //获取需要转化的图片
    imgToBase64(e) {
      let that = this;
      this.photoUrl = '';
      this.dataGarbage = [];
      let file = e.target.files[0];
      e.target.value = '';
      let obj = new Date().getTime();
      let storeAs = obj + "." + file.name;
      const client = new OSS({
        region: "oss-cn-beijing",
        accessKeyId: "LTAI5tAhC8qbUaiTHPh7dEuA",
        accessKeySecret: "TfALWBoft5i0A2P11Vxd1DGqBqj9bE",
        bucket: "rubbish-demo",
      });
      if (this.beforeUpload(file)) {
        client.put(storeAs, file).then(results => {
          that.photoUrl = results.url
        });
        let imgFile = new FileReader();
        imgFile.readAsDataURL(file);
        imgFile.onload = function () {
          that.$refs.imgRef.src = this.result;
          that.base64Url = this.result.toString().replace('data:image/jpeg;base64,', '');
        }
      }
      this.dataGarbage = [];
    },
    //图片转base64格式
    beforeUpload(file) {
      const jspicture =
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg";
      if (!jspicture) {
        message.error(file.name + "上传文件格式错误");
      }
      return jspicture;
    },
    //调用京东api并返回结果
    useJDapi() {
      this.isLoading = true;
      this.$axios({
        url: "http://39.100.158.75:8080/other/getPhotoInfo",
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          photoUrl: this.photoUrl
        })
      }).then(res => {
        this.photoUrl = "";
        this.isLoading = false;
        this.dataGarbage = res.data.data.result.garbage_info;
        for (const el of this.dataGarbage) {
          el.confidence = (el.confidence * 100).toFixed(2) + '%';
        }
      });
    }
  },
  mounted() {
    this.getCharmingImages();
    //监听页面的滚动条变化
    document.addEventListener("scroll", this.scrollListen, true);
    message.warning('本页面建议竖屏使用！', 4);
  },
  unmounted() {
    document.removeEventListener("scroll", this.scrollListen, true);
  }
});
</script>

<style lang="scss" scoped>
#global-box {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  .button-box {
    width: 100%;
    padding: 5px;
    .img {
      width: 35%;
      margin-right: 20px;
    }
    .rubbish-button {
      background-color: #0e9a00;
    }
  }
  .images-box {
    width: 100%;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item-box {
      width: 30vw;
      height: 30vw;
      margin-bottom: 5px;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-box:hover {
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.1);
      border: 3px solid transparent;
    }
  }
  .analysis-box {
    position: fixed;
    right: 2.5%;
    top: 7px;
    width: 38%;
    height: 96vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.1);
    .button-box {
      margin-top: 20px;
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .analysis-button {
        width: 40%;
        height: 80%;
        font-size: 22px;
        font-weight: 550;
      }
      .back-button {
        width: 10%;
        height: 80%;
        font-size: 30px;
        font-weight: 600;
        color: #FFF;
        background-color: #ff0347;
      }
    }
    img {
      display: block;
      margin: 5vh auto;
      width: 50%;
    }
    :deep() .ant-table-pagination .ant-pagination {
      display: none !important;
    }
    :deep() .ant-pagination {
      display: none !important;
    }
  }
}
</style>
